import React from 'react'
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import { LinkIcon, TicketIcon } from '@heroicons/react/24/outline'

import { TicketType } from '~types'
import { Table } from '~components'

type TicketTypesTableProps = {
  ticketTypes: TicketType[]
}

export const TicketTypesTable: React.FC<TicketTypesTableProps> = ({ ticketTypes }) => {
  const { t } = useTranslation()
  const [sorting, setSorting] = React.useState<SortingState>([])
  const columnHelper = createColumnHelper<TicketType>()

  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: 'name',
      cell: (info) => (
        <Link
          className="flex items-center"
          to={`/ticket-categories/${info.row.original.category.id}/tickets/${info.row.original.uid}/edit`}>
          {info.row.original.isBundledOnly ? (
            <LinkIcon className="w-4 h-4 text-teal-600" />
          ) : (
            <TicketIcon className="w-4 h-4 text-teal-600" />
          )}
          <i className="pl-4">{info.getValue()}</i>
        </Link>
      ),
      header: () => t('Fields.name', 'Name'),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('pricePer', {
      header: () => t('Fields.pricePer', 'Price per'),
      footer: (info) => info.column.id,
      cell: (info) => (info.row.original.isBundledOnly ? '---' : info.getValue()),
    }),
    columnHelper.accessor('vatRate', {
      header: () => t('Fields.vatRate', 'VAT Rate'),
      footer: (info) => info.column.id,
      cell: (info) => (info.row.original.isBundledOnly ? '---' : info.getValue()),
    }),
    columnHelper.accessor('category', {
      header: () => t('Fields.category', 'Category'),
      footer: (info) => info.column.id,
      cell: (info) => info.getValue().name,
    }),
    columnHelper.accessor('position', {
      header: () => t('Fields.position', 'Position'),
      footer: (info) => info.column.id,
      cell: (info) => (info.row.original.isBundledOnly ? '---' : info.getValue()),
    }),
    columnHelper.accessor('isActive', {
      header: () => t('Fields.isActive', 'Is active'),
      footer: (info) => info.column.id,
      cell: (info) =>
        info.getValue() ? (
          <CheckCircleIcon className="w-4 h-4 text-green-700" />
        ) : (
          <XCircleIcon className="w-4 h-4 text-red-700" />
        ),
    }),
    columnHelper.accessor('createdAt', {
      header: t('Fields.createdAt', 'Created at'),
      footer: (info) => info.column.id,
      cell: (info) => new Date(info.getValue()).toLocaleDateString(),
    }),
  ]
  const table = useReactTable<TicketType>({
    data: ticketTypes,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  })

  return <Table table={table} key="tickets" />
}
