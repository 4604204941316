import React from 'react'
import { useTranslation } from 'react-i18next'

import { HeaderLayout } from '~components'

export const MyProfilePage: React.FC = () => {
  const { t } = useTranslation()
  return (
    <HeaderLayout>
      <div className="relative h-full">
        <div className="flex flex-col p-8 h-full">
          <div>{t('ProfilePage.title', 'Profile')}</div>
        </div>
      </div>
    </HeaderLayout>
  )
}
