import jwt_decode, { JwtPayload } from 'jwt-decode'

import useApi from './api'

import { UserInfo } from '~types'
import i18n from '~languages'

interface DecodedToken extends JwtPayload {
  token_type: string
  exp: number
  iat: number
  jti: string
  user_id: number
  email: string
  first_name: string
  last_name: string
  is_verified: boolean
  is_active: boolean
  superuser: boolean
  staff: boolean
}

type StoreTokenFunc = ({
  token,
  username,
  roles,
}: {
  token: string
  username: string
  roles: string
}) => void
const storeToken: StoreTokenFunc = ({ token, username, roles }) => {
  localStorage.setItem('token', token)
  localStorage.setItem('username', username)
  localStorage.setItem('roles', roles)
}

type ClearTokenFunc = () => void
const clearToken: ClearTokenFunc = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('username')
  localStorage.removeItem('roles')
}

type ValidateTokenFunc = (token: string) => boolean
const validateToken: ValidateTokenFunc = (token) => {
  if (token) {
    const now = Date.now().valueOf() / 1000
    const decoded: DecodedToken = jwt_decode(token)
    if (typeof decoded.exp !== 'undefined' && decoded.exp < now && !decoded.staff) {
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}

type DecodeTokenFunc = (token: string) => DecodedToken
const decodeToken: DecodeTokenFunc = (token) => {
  const decoded: DecodedToken = jwt_decode(token)
  return decoded
}

type AuthErrorResponse = { _type: 'auth_error_response'; status: string; message: string }

type StatsTokenResponse = {
  _type: 'stats_token_response'
  accessToken: string
  tokenType: string
  expiresIn: number
}
type GetStatsTokenFunc = () => Promise<StatsTokenResponse | AuthErrorResponse>
const getStatsToken: GetStatsTokenFunc = async () => {
  const api = useApi()
  const response = await api.get('/users/statistics-token/')

  if (response.status === 200) {
    return {
      _type: 'stats_token_response' as const,
      ...(response.data as { accessToken: string; tokenType: string; expiresIn: number }),
    }
  }
  return {
    status: 'error',
    message: i18n.t('Errors.statsTokenError', 'Could not get statistics token'),
    _type: 'auth_error_response' as const,
  }
}

type AuthMyUserResponse = {
  _type: 'auth_my_user_response'
  status: string
} & UserInfo
type GetMyUserInfo = () => Promise<AuthMyUserResponse | AuthErrorResponse>
const getMyUserInfo: GetMyUserInfo = async () => {
  const api = useApi()
  const response = await api.get('/users/me/')

  if (response.status === 200) {
    return {
      status: 'ok',
      ...(response.data as UserInfo),
      _type: 'auth_my_user_response' as const,
    }
  }
  return {
    status: 'error',
    message: i18n.t('Errors.serverError', 'Server error'),
    _type: 'auth_error_response' as const,
  }
}

const authService = {
  getMyUserInfo,
  validateToken,
  storeToken,
  clearToken,
  getStatsToken,
  decodeToken,
}

export default authService
