import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { PanelHeader, RightPanel, TicketTypeForm } from '~components'
import { CreateTicketTypeProps, TicketType } from '~types'
import useApi from '~api/api'

export const TicketTypeAddPage = () => {
  const { t } = useTranslation()
  const { categoryId } = useParams<{ categoryId: string }>()
  const navigate = useNavigate()
  const api = useApi()
  const queryClient = useQueryClient()

  const {
    mutateAsync: addTicketType,
    isPending: loadingAddTicketType,
    reset: resetAddTicketType,
  } = useMutation({
    mutationFn: async ({ ticketType }: { ticketType: CreateTicketTypeProps }) => {
      const response = await api.post<TicketType>(`/tickets/`, ticketType)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['categories', categoryId, 'tickets'] })
      toast.success(t('CreateTicketScreen.createdMessage', 'Ticket created!'))
      navigate(`/ticket-categories/${categoryId}/tickets`)
    },
    onError: () => toast.error(t('CreateTicketScreen.createError', "Couldn't create Ticket")),
  })

  return (
    <RightPanel>
      <PanelHeader
        returnsTo={`/ticket-categories/${categoryId}/tickets`}
        title={t('CreateTicketScreen.title', 'Create a new Ticket')}
        subtitle={t('CreateTicketScreen.subtitle', 'Offer a new Ticket to your customers.')}
      />
      <TicketTypeForm
        submitLabel={t('General.create', 'Create')}
        initialValues={
          {
            isActive: true,
            name: '',
            pricePer: 0,
            vatRate: '',
            minQuantity: 1,
            category: categoryId,
            isBundledOnly: false,
            isFavorite: false,
          } as CreateTicketTypeProps
        }
        onSubmit={async (values, actions) => {
          await addTicketType(
            { ticketType: values },
            {
              onSuccess: () => {
                actions.resetForm()
                resetAddTicketType()
              },
            },
          )
        }}
        loading={loadingAddTicketType}
      />
    </RightPanel>
  )
}
