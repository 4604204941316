import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { Trans, useTranslation } from 'react-i18next'

import { FormDisplay, PanelHeader, RightPanel, TransportForm } from '~components'
import {
  useEditTransport,
  useGetTransport,
  useRenderError,
  useToastConfirm,
  useToastError,
} from '~hooks'
import { EditTransportErrors, EditTransportProps } from '~types'

export const TransportEditPage = () => {
  const { t } = useTranslation()
  const params = useParams<{ transportId: string }>()
  if (!params.transportId) {
    throw Error('You must provide a transportId using the url.')
  }
  const transportId = params.transportId
  const navigate = useNavigate()

  const {
    mutateAsync: editTransport,
    isPending: updatingTransport,
    error: editTransportError,
  } = useEditTransport()
  const {
    data: initialValues,
    isLoading: loadingTransport,
    error: fetchTransportError,
  } = useGetTransport({
    transportId,
  })
  const fetchError = useRenderError({
    error: fetchTransportError,
    defaultMessage: t('EditTransportScreen.fetchError', "Couldn't get transport."),
  })
  const editError = useRenderError({
    error: editTransportError,
    defaultMessage: t('EditTransportScreen.updateError', "Couldn't update transport."),
  })

  const queryClient = useQueryClient()
  const showError = useToastError()
  const showConfirm = useToastConfirm()

  React.useEffect(() => {
    if (editError) {
      showError(editError)
    }
  }, [editError])

  return (
    <RightPanel>
      <PanelHeader
        returnsTo="/transports"
        title={t('EditTransportScreen.title', 'Edit Transport')}
        subtitle={
          <p>
            <Trans
              i18nKey={`EditTransportScreen.subtitle`}
              values={{
                resource: initialValues?.identifier,
              }}
              components={{
                strong: <strong />,
              }}
            />
          </p>
        }
      />
      <FormDisplay isLoading={loadingTransport} error={fetchError}>
        {initialValues && (
          <TransportForm
            initialValues={initialValues}
            onSubmit={async (values, actions) => {
              try {
                await editTransport({ transport: values as EditTransportProps })
                queryClient.invalidateQueries({ queryKey: ['transports'] })
                queryClient.invalidateQueries({
                  queryKey: ['transport', transportId],
                })
                showConfirm(
                  t('EditTransportScreen.updatedMesage', 'Transport updated successfully.'),
                )
                navigate('/transports')
              } catch (e) {
                actions.setErrors(e as EditTransportErrors)
              }
            }}
            loading={updatingTransport}
          />
        )}
      </FormDisplay>
    </RightPanel>
  )
}
