/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react'
import { useTranslation } from 'react-i18next'

import { TableSkeleton } from '~components'

type TableDisplayProps = {
  children: React.ReactNode
  items: Array<any>
  isLoading?: boolean
  error?: unknown
  loadingEl?: React.ReactNode
  errorEl?: React.ReactNode
  emptyEl?: React.ReactNode
}

export const TableDisplay: React.FC<TableDisplayProps> = ({
  children,
  items,
  isLoading,
  error,
  loadingEl,
  errorEl,
  emptyEl,
}) => {
  const { t } = useTranslation()
  const renderError = (error: any) => {
    if (errorEl) {
      return errorEl
    }
    if (Object.prototype.hasOwnProperty.call(error, 'message')) {
      return <p>Error: {JSON.stringify(error.message)}</p>
    }
    if (error) {
      return <p>{error}</p>
    }
    return <p>{t('General.unknownError', 'There was an error.')}</p>
  }

  return (
    <>
      {isLoading ? (
        loadingEl ? (
          loadingEl
        ) : (
          <TableSkeleton />
        )
      ) : error ? (
        renderError(error)
      ) : items.length === 0 ? (
        emptyEl ? (
          emptyEl
        ) : (
          <p>There are no items to display</p>
        )
      ) : (
        <>{children}</>
      )}
    </>
  )
}

/* eslint-enable @typescript-eslint/no-explicit-any */
