import { useQuery } from '@tanstack/react-query'
import React from 'react'

import { Voucher } from '~types'
import vouchersService from '~api/vouchers.service'

export type VouchersContextData = object

const VouchersContext = React.createContext<VouchersContextData>({} as VouchersContextData)

export const useVouchers = () => {
  return React.useContext(VouchersContext)
}

export const VouchersProvider = ({ children }: { children: React.ReactNode }) => {
  const vouchersContext = useVouchersProvider()
  return <VouchersContext.Provider value={vouchersContext}>{children}</VouchersContext.Provider>
}

export const useListVouchers = () => {
  return useQuery<Voucher[]>({
    queryKey: ['vouchers'],
    queryFn: vouchersService.listVouchers,
  })
}

const useVouchersProvider = () => ({})
