import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { Button, HeaderLayout, TableDisplay, TransportsTable } from '~components'
import { useListTransports } from '~hooks'

export const TransportListPage = () => {
  const { t } = useTranslation()
  const {
    data: transports,
    isLoading: loadingListTransports,
    error: listTransportsError,
  } = useListTransports()
  const queryClient = useQueryClient()

  React.useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['transports'] })
  }, [])

  return (
    <HeaderLayout>
      <div className="relative h-full">
        <div className="flex flex-col p-8 h-full">
          <div>
            <Link
              to="/transports/add"
              className="inline-flex items-center text-sm font-medium text-gray-900 py-4">
              <Button>{t('ListTransportsScreen.newTransport', 'New Transport')}</Button>
            </Link>
          </div>
          <TableDisplay
            items={transports || []}
            isLoading={loadingListTransports}
            error={listTransportsError}>
            <TransportsTable transports={transports || []} />
          </TableDisplay>
        </div>
        <div className="fixed right-0 top-0 h-full">
          <Outlet />
        </div>
      </div>
    </HeaderLayout>
  )
}
