import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { HeaderLayout, SalesTable, TableDisplay } from '~components'
import ticketsService from '~api/tickets.service'

export const SalesListPage = () => {
  // const queryClient = useQueryClient()

  const [page, setPage] = useState(0)

  setPage(0)
  const {
    data: transactions,
    isLoading: loadingTransactions,
    error: transactionsError,
  } = useQuery({
    queryKey: ['transactions', page],
    queryFn: async () => {
      ticketsService.listSoldTickets(page)
    },
  })

  if (!transactions) {
    return null
  }

  return (
    <HeaderLayout>
      <div className="relative h-full">
        <div className="flex flex-col p-8 h-full">
          <TableDisplay items={[]} isLoading={loadingTransactions} error={transactionsError}>
            <SalesTable
              activePage={page}
              pages={2}
              soldTickets={[]}
              onNextPage={() => {}}
              onPreviousPage={() => {}}
              onPage={(page) => page}
            />
          </TableDisplay>
        </div>
        <div className="fixed right-0 top-0 h-full">
          <Outlet />
        </div>
      </div>
    </HeaderLayout>
  )
}
