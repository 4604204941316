import useApi from './api'

import { Voucher } from '~types'

const listVouchers = async () => {
  const api = useApi()
  const response = await api.get<Voucher[]>('/api/integrations/vouchers/')
  return response.data
}

const vouchersService = {
  listVouchers,
}

export default vouchersService
