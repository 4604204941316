import React from 'react'

import { Header } from '~components'

export const HeaderLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="h-full grid grid-rows-[auto_1fr]">
      <Header />
      <div className="overflow-scroll h-full">{children}</div>
    </div>
  )
}
