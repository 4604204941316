import { useMutation, useQuery } from '@tanstack/react-query'
import React from 'react'

import {
  CreateTransportProps,
  EditTransportProps,
  Transport,
  TransportID,
  TransportStatus,
  TransportType,
} from '~types'
import transportsService from '~api/transports.service'

export type TransportsContextData = object

const TransportsContext = React.createContext<TransportsContextData>({} as TransportsContextData)

export const useTransports = () => {
  return React.useContext(TransportsContext)
}

export const TransportsProvider = ({ children }: { children: React.ReactNode }) => {
  const transportsContext = useTransportsProvider()
  return (
    <TransportsContext.Provider value={transportsContext}>{children}</TransportsContext.Provider>
  )
}

export const useListTransportTypes = () => {
  return useQuery<TransportType[]>({
    queryKey: ['transportTypes'],
    queryFn: transportsService.listTransportTypes,
  })
}

export const useListTransportStatuses = () => {
  return useQuery<TransportStatus[]>({
    queryKey: ['transportStatuses'],
    queryFn: transportsService.listTransportStatuses,
  })
}

export const useListTransports = () => {
  return useQuery<Transport[]>({
    queryKey: ['transports'],
    queryFn: transportsService.listTransports,
  })
}

export const useAddTransport = () => {
  return useMutation({
    mutationFn: async ({ transport }: { transport: CreateTransportProps }) =>
      transportsService.addTransport({ transport }),
  })
}

export const useGetTransport = ({ transportId }: { transportId: TransportID }) => {
  return useQuery<Transport>({
    queryKey: ['transport', transportId],
    queryFn: async () => transportsService.getTransport({ transportId }),
  })
}

export const useEditTransport = () => {
  return useMutation({
    mutationFn: async ({ transport }: { transport: EditTransportProps }) =>
      transportsService.editTransport({ transport }),
  })
}

export const useDeleteTransport = () => {
  return useMutation({
    mutationFn: async ({ transportId }: { transportId: TransportID }) =>
      transportsService.deleteTransport({ transportId }),
  })
}

const useTransportsProvider = () => ({})
