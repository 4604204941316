import React from 'react'
import clsx from 'clsx'

type ButtonStyle = 'primary' | 'secondary' | 'danger'

type ButtonProps = {
  children: React.ReactNode
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type']
  className?: React.HTMLAttributes<HTMLDivElement>['className']
  style?: ButtonStyle
  loading?: boolean
} & React.ButtonHTMLAttributes<HTMLButtonElement>

export const Button: React.FC<ButtonProps> = ({
  children,
  type,
  className,
  style = 'primary',
  loading,
  ...props
}) => {
  const { disabled, ...rest } = props
  return (
    <div className={clsx(className, 'flex')}>
      <button
        type={type || 'button'}
        disabled={disabled || loading}
        className={clsx(
          'justify-center h-8 py-2 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border disabled:opacity-50 disabled:pointer-events-none hover:pointer-events-auto',
          style === 'primary' && 'border-transparent bg-teal-600 text-white hover:bg-teal-700',
          style === 'secondary' && 'border-gray-300 bg-white text-gray-800 hover:text-gray-500 ',
          style === 'danger' &&
            'border-gray-300 bg-white text-red-700 hover:text-red-700 hover:border-red-700',
        )}
        {...rest}>
        {children}
      </button>
    </div>
  )
}
