import { LinkIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import useApi from '~api/api'
import { BlockSkeleton, Button } from '~components'
import { TicketType } from '~types'

export const BundleTicketsList: React.FC = () => {
  const { ticketTypeId: ticketId, categoryId } = useParams<{
    ticketTypeId: string
    categoryId: string
  }>()
  const { t } = useTranslation()
  const api = useApi()
  //   const navigate = useNavigate()
  const queryClient = useQueryClient()

  const bundledTickets = useQuery<TicketType[]>({
    queryKey: ['bundledTickets', ticketId],
    queryFn: async () => {
      const response = await api.get<TicketType[]>(`/tickets/${ticketId}/bundles/`)
      return response.data
    },
    enabled: !!categoryId && !!ticketId,
  })

  //   const { mutateAsync: bundledTicket, isPending: bundlingTicket } = useMutation({
  //     mutationFn: async (bundledTicketId: string) => {
  //       const response = await api.post<TicketType>(`/tickets/${ticketId}/bundles/`, {
  //         bundledTicketId,
  //       })
  //       return response.data
  //     },
  //     onSuccess: () => {
  //       queryClient.invalidateQueries({
  //         queryKey: ['categories', categoryId, 'tickets', ticketId, 'bundledTickets'],
  //       })
  //       toast.success(t('EditTicketScreen.updatedMessage', 'Ticket bundle updated!'))
  //     },
  //     onError: () => toast.error(t('EditTicketScreen.bundlingError', 'Error bundling Ticket Type.')),
  //   })

  const { mutateAsync: unBundledTicket } = useMutation({
    mutationFn: async (bundleId: string) =>
      await api._delete(`/tickets/${ticketId}/bundles/${bundleId}/`),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['categories', categoryId, 'tickets', ticketId, 'bundledTickets'],
      })
      toast.success(t('EditTicketScreen.updatedMessage', 'Ticket Type updated successfully.'))
    },
    onError: () =>
      toast.error(t('EditTicketScreen.unbundlingError', 'Error unbundling Ticket Type.')),
  })

  if (!bundledTickets || bundledTickets.isLoading) {
    return <BlockSkeleton />
  }

  return (
    <>
      {bundledTickets.isError && <p className="text-red-600">{bundledTickets.error.message}</p>}
      {bundledTickets.data && bundledTickets.data.length === 0 && (
        <p>No tickets are bundled to this ticket type yet</p>
      )}
      {bundledTickets.data && (
        <ul className="grid grid-cols-2 gap-4">
          {bundledTickets.data.map((ticket) => (
            <li key={ticket.uid} className="grid grid-cols-[1fr_auto] gap-4 border p-4 rounded-lg">
              <div className="flex items-center">
                <LinkIcon className="w-4 h-4 mr-2" />
                <p className="pr-2">
                  <i>{ticket.name}</i>
                </p>
                <p className="pr-2 text-sm">
                  (
                  <span
                    className={clsx(
                      ticket.isActive && 'text-teal-600',
                      !ticket.isActive && 'text-red-600',
                    )}>
                    {ticket.isActive ? 'Active' : 'Inactive'}
                  </span>
                  )
                </p>
                <XMarkIcon className="w-4 h-4 text-gray-600" />
              </div>
              <div>
                <Button onClick={() => unBundledTicket(ticket.uid)} style="danger">
                  <TrashIcon className="w-4 h-4 text-red-600" />
                </Button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </>
  )
}
