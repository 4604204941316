import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '~hooks'
import { HeaderLayout, PasswordInput } from '~components'
import { ResetPasswordErrors } from '~types'

export const ResetPasswordPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const code = new URLSearchParams(window.location.search).get('code') || ''
  const [loading, setLoading] = React.useState(false)

  return (
    <HeaderLayout>
      <div className="w-full h-full py-4 px-8">
        <div className="flex flex-col items-center">
          <p className="pt-4">{t('ResetPasswordScreen.text', 'Provide a new password.')}</p>
          <PasswordInput
            loading={loading}
            onSubmit={async (values, actions) => {
              setLoading(true)
              const error = await auth.resetPassword({ password: values.password, code })
              setLoading(false)
              if (!error) {
                navigate('/login')
              } else {
                actions.setErrors({ password: error } as ResetPasswordErrors)
              }
            }}
          />
        </div>
      </div>
    </HeaderLayout>
  )
}
