export const FormSkeleton: React.FC = () => {
  return (
    <div className="flex animate-pulse">
      <div className="w-full">
        <ul className="mb-5 space-y-3">
          <li className="w-full h-8 bg-gray-200 rounded-md"></li>
          <li className="w-full h-8 bg-gray-200 rounded-md"></li>
          <li className="w-full h-8 bg-gray-200 rounded-md"></li>
          <li className="w-full h-8 bg-gray-200 rounded-md"></li>
          <li className="w-full h-8 bg-gray-200 rounded-md"></li>
        </ul>

        <button className="h-8 bg-gray-200 rounded-md w-2/5"></button>
      </div>
    </div>
  )
}

export const BlockSkeleton: React.FC = () => {
  return (
    <div className="flex animate-pulse">
      <div className="w-full h-16 bg-gray-200 rounded-md"></div>
    </div>
  )
}

export const TableSkeleton: React.FC = () => {
  return (
    <div className="flex animate-pulse">
      <div className="w-full">
        <div className="flex w-full justify-between mb-5">
          <div className="w-2/5 h-8 bg-gray-200 rounded-md"></div>
          <div className="w-2/5 h-8 bg-gray-200 rounded-md"></div>
        </div>
        <div className="w-full h-80 bg-gray-200 rounded-md"></div>
      </div>
    </div>
  )
}
