import React from 'react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import numeral from 'numeral'

import { NoData } from '~components'

type Top5Data = {
  ticketsSoldTicketTypeName: string
  ticketsSoldTicketTypeCategoryName: string
  count?: number
  total?: number
}

interface Props {
  tableHeader: string
  data: Top5Data[]
  showCount?: boolean
  showTotal?: boolean
}

export const Top5Table: React.FC<Props> = ({ tableHeader, data, showCount, showTotal }) => {
  const column = createColumnHelper<Top5Data>()

  const columns = [
    column.group({
      header: () => (
        <div className="flex justify-between">
          <h2 className="text-lg font-medium leading-6 text-gray-900">{tableHeader}</h2>
        </div>
      ),
      id: 'group',
      columns: [
        column.accessor('ticketsSoldTicketTypeCategoryName', { header: 'Category' }),
        column.accessor('ticketsSoldTicketTypeName', { header: 'Name' }),
        column.accessor('count', {
          header: 'Count',
          cell: (info) => <div className="text-right">{numeral(info.getValue()).format('0')}</div>,
        }),
        column.accessor('total', {
          header: 'Total',
          cell: (info) => (
            <div className="text-right">{numeral(info.getValue()).format('0,0.00')}</div>
          ),
        }),
      ],
    }),
  ]

  const instance = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      columnVisibility: {
        count: showCount || false,
        total: showTotal || false,
      },
    },
  })
  return (
    <div className="mx-auto mt-8">
      <div className="mt-2 flex flex-col">
        <div className="min-w-full overflow-scroll align-middle shadow sm:rounded-lg">
          <div className="overflow-scroll shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                {instance.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        className={'text-left text-sm font-semibold text-gray-900'}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {instance.getRowModel().rows.map((row, rIdx) => (
                  <tr key={row.id} className={rIdx % 2 === 0 ? 'bg-gray-50' : 'bg-gray-100'}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className={'whitespace-nowrap py-2 text-sm text-gray-500'}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <NoData show={data.length === 0} />
          </div>
        </div>
      </div>
    </div>
  )
}
