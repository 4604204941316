import React, { createContext, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { Auth, AuthSuccessPayload } from '~types'
import authService from '~api/auth.service'
import useApi from '~api/api'

export interface AuthContextInterface {
  loggedIn: boolean
  firstName: string
  lastName: string
  token: string | undefined
  refreshToken: string | undefined
  login: (username: string, password: string) => void
  logout: () => Promise<void>
  refresh: (token: string) => void
  resetPassword: (data: { password: string; code: string }) => Promise<string>
  requestPasswordReset: (data: { email: string }) => void
}

const BASE_AUTH_STATE: Auth = {
  loggedIn: false,
  firstName: '',
  lastName: '',
  token: undefined,
  refreshToken: undefined,
}

const AuthContext = createContext<AuthContextInterface>({} as AuthContextInterface)

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const auth = useAuthProvider()
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}

const useAuthProvider = (): AuthContextInterface => {
  const { t } = useTranslation()
  const api = useApi()
  const [authState, setAuthState] = useState<Auth>(BASE_AUTH_STATE)

  useEffect(() => {
    const token = localStorage.getItem('token')
    const refreshToken = localStorage.getItem('refreshToken')

    if (token && refreshToken) {
      const isValid = authService.validateToken(token)
      const tokenData = authService.decodeToken(token)
      setAuthState({
        loggedIn: isValid,
        token: token,
        refreshToken: refreshToken,
        firstName: tokenData.first_name,
        lastName: tokenData.last_name,
      })
    }
  }, [])

  const refresh = (token: string) => {
    localStorage.setItem('token', token)
    setAuthState({
      ...authState,
      token,
    })
  }

  const login = async (email: string, password: string): Promise<void> => {
    const response = await api.post<AuthSuccessPayload>('/users/token/', { email, password })

    if (response.status === 200 && response.data.access) {
      localStorage.setItem('token', response.data.access)
      localStorage.setItem('refreshToken', response.data.refresh)
      const tokenData = authService.decodeToken(response.data.access)
      if (tokenData.staff) {
        setAuthState({
          loggedIn: true,
          token: response.data.access,
          refreshToken: response.data.refresh,
          firstName: tokenData.first_name,
          lastName: tokenData.last_name,
        })
      }
      return
    } else {
      toast.error(t('SignInScreen.invalidCredentialsText', 'Invalid credentials'))
      return
    }
  }

  const logout = async () => {
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    setAuthState(BASE_AUTH_STATE)
  }

  const requestPasswordReset = async (data: { email: string }) => {
    const response = await api.post('/users/request-password-reset/', data)
    if (response.status !== 200) {
      toast.error(t('Errors.resetPasswordError', "Couldn't request reset password"))
      return
    } else {
      toast.success(t('RequestPasswordResetScreen.success', 'Password reset requested.'))
      return
    }
  }

  const resetPassword = async (data: { password: string; code: string }) => {
    const response = await api.post('/users/reset-password/', data)

    if (response.status !== 200) {
      toast.error(t('Errors.resetPasswordError', "Couldn't reset password"))
      return t('Errors.resetPasswordError', "Couldn't reset password")
    } else {
      toast.success(t('ResetPasswordScreen.success', 'Password reseted successfully.'))
      return null
    }
  }

  return {
    loggedIn: authState.loggedIn,
    firstName: authState.firstName,
    lastName: authState.lastName,
    token: authState.token,
    refreshToken: authState.refreshToken,
    login,
    logout,
    resetPassword,
    refresh,
    requestPasswordReset,
  } as AuthContextInterface
}
