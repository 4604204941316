import 'react-toastify/dist/ReactToastify.min.css'
import { createBrowserRouter, LoaderFunctionArgs, redirect, RouterProvider } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Footer, NoMatch } from '~components'
import { useAuth } from '~hooks'
import {
  DashboardPage,
  ExportPage,
  LoginPage,
  MyProfilePage,
  RequestPasswordResetPage,
  ResetPasswordPage,
  SalesListPage,
  TicketCategoriesListPage,
  TicketCategoryAddPage,
  TicketCategoryEditPage,
  TicketTypeAddPage,
  TicketTypeEditPage,
  TicketTypesListPage,
  TicketTypeViewPage,
  TransportAddPage,
  TransportEditPage,
  TransportListPage,
  UserAddPage,
  UserEditPage,
  UsersListPage,
  VouchersListPage,
} from '~pages'
import { BundleTicketsList } from '~pages/BundledTickets'

export const Main = () => {
  const auth = useAuth()
  const { i18n } = useTranslation()

  const storedLocale = localStorage.getItem('locale')
  if (storedLocale && storedLocale !== i18n.language) {
    i18n.changeLanguage(storedLocale)
  }

  const loginLoader = ({ request }: LoaderFunctionArgs) => {
    const url = new URL(request.url)
    const fromPath = url.searchParams.get('from')
    if (auth.loggedIn) {
      return redirect(fromPath || '/')
    }
    return null
  }

  const protectedLoader = async (context: LoaderFunctionArgs) => {
    const { pathname } = new URL(context.request.url)
    const token = localStorage.getItem('token')

    // TODO: include one more check to validate the token is not expired
    if (!token || token === 'undefined' || token === 'null') {
      await auth.logout()
      return redirect(`/login?from=${pathname}`)
    }
    return null
  }

  const router = createBrowserRouter([
    {
      path: '/',
      loader: (context) => protectedLoader(context),
      element: <DashboardPage />,
      children: [
        {
          path: '/export',
          loader: (context) => protectedLoader(context),
          element: <ExportPage />,
        },
      ],
    },
    {
      path: '/transports',
      loader: (context) => protectedLoader(context),
      element: <TransportListPage />,
      children: [
        {
          path: '/transports/add',
          loader: (context) => protectedLoader(context),
          element: <TransportAddPage />,
        },
        {
          path: '/transports/:transportId/edit',
          loader: (context) => protectedLoader(context),
          element: <TransportEditPage />,
        },
      ],
    },
    {
      path: '/ticket-categories',
      loader: (context) => protectedLoader(context),
      element: <TicketCategoriesListPage />,
      children: [
        {
          path: '/ticket-categories/add',
          loader: (context) => protectedLoader(context),
          element: <TicketCategoryAddPage />,
        },
        {
          path: '/ticket-categories/:ticketCategoryId/edit',
          loader: (context) => protectedLoader(context),
          element: <TicketCategoryEditPage />,
        },
      ],
    },
    {
      path: '/ticket-categories/:categoryId/tickets',
      loader: (context) => protectedLoader(context),
      element: <TicketTypesListPage />,
      children: [
        {
          path: '/ticket-categories/:categoryId/tickets/add',
          loader: (context) => protectedLoader(context),
          element: <TicketTypeAddPage />,
        },
        {
          path: '/ticket-categories/:categoryId/tickets/:ticketTypeId',
          loader: (context) => protectedLoader(context),
          element: <TicketTypeViewPage />,
        },
        {
          path: '/ticket-categories/:categoryId/tickets/:ticketTypeId/edit',
          loader: (context) => protectedLoader(context),
          element: <TicketTypeEditPage />,
          children: [
            {
              path: '',
              loader: (context) => protectedLoader(context),
              element: <BundleTicketsList />,
            },
          ],
        },
      ],
    },
    {
      path: '/users',
      loader: (context) => protectedLoader(context),
      element: <UsersListPage />,
      children: [
        {
          path: '/users/add',
          loader: (context) => protectedLoader(context),
          element: <UserAddPage />,
        },
        {
          path: '/users/:userId/edit',
          loader: (context) => protectedLoader(context),
          element: <UserEditPage />,
        },
      ],
    },
    {
      path: '/vouchers',
      loader: (context) => protectedLoader(context),
      element: <VouchersListPage />,
    },
    {
      path: '/sales',
      loader: (context) => protectedLoader(context),
      element: <SalesListPage />,
    },
    {
      path: '/login',
      loader: loginLoader,
      element: <LoginPage />,
    },
    {
      path: '/profile',
      loader: (context) => protectedLoader(context),
      element: <MyProfilePage />,
    },
    {
      path: '/request-password-reset',
      element: <RequestPasswordResetPage />,
    },
    {
      path: '/reset-password',
      element: <ResetPasswordPage />,
    },
    {
      path: '/*',
      element: <NoMatch />,
    },
  ])

  return (
    <>
      <RouterProvider router={router} />
      <Footer />
    </>
  )
}
