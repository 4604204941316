import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { Button, InputField } from '~components'
import { CreateUserProps, EditUserProps } from '~types'

type InitialValues = CreateUserProps | EditUserProps

type UserFormProps = {
  initialValues: InitialValues
  submitType: 'create' | 'edit'
  onSubmit: (values: InitialValues, actions: FormikHelpers<InitialValues>) => void
  loading?: boolean
}

export const UserForm: React.FC<UserFormProps> = ({
  initialValues,
  submitType,
  onSubmit,
  loading,
}: UserFormProps) => {
  const { t } = useTranslation()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required(t('ValidationMessages.required', 'Is required')),
        lastName: Yup.string().required(t('ValidationMessages.required', 'Is required')),
        email: Yup.string().email().required(t('ValidationMessages.required', 'Is required')),
      })}
      onSubmit={onSubmit}
      enableReinitialize>
      {({ values, dirty }) => (
        <Form className="flex flex-col">
          <InputField
            name="isActive"
            type="switch"
            label={
              values.isActive ? t('Fields.active', 'Active') : t('Fields.inactive', 'Inactive')
            }
            className="pb-2 self-end text-lg"
            invertedLabel
          />
          <InputField
            name="email"
            disabled={submitType === 'edit'}
            type="text"
            label={t('Fields.email', 'Email')}
            className="pb-2"
          />
          <InputField
            name="firstName"
            type="text"
            label={t('Fields.firstName', 'First Name')}
            className="pb-2"
          />
          <InputField
            name="lastName"
            type="text"
            label={t('Fields.lastName', 'Last Name')}
            className="pb-2"
          />
          <Button type="submit" className="pt-4" loading={loading} disabled={!dirty}>
            {submitType === 'edit' ? t('General.update', 'Update') : t('General.create', 'Create')}{' '}
            {t('General.user', 'User')}
          </Button>
        </Form>
      )}
    </Formik>
  )
}
