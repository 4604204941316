import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'

import { Button, InputField } from '~components'
import { CreateTicketTypeProps, EditTicketTypeProps, TicketCategory, VatRate } from '~types'
import useApi from '~api/api'

type InitialValues = CreateTicketTypeProps | EditTicketTypeProps

type TicketTypeFormProps = {
  initialValues: InitialValues
  submitLabel: string
  onSubmit: (values: InitialValues, actions: FormikHelpers<InitialValues>) => void
  loading?: boolean
}

export const TicketTypeForm: React.FC<TicketTypeFormProps> = ({
  initialValues,
  submitLabel,
  onSubmit,
  loading,
}: TicketTypeFormProps) => {
  const { t } = useTranslation()
  const api = useApi()

  const ticketCategories = useQuery<TicketCategory[]>({
    queryKey: ['categories'],
    queryFn: async () => {
      const response = await api.get<TicketCategory[]>('/tickets/categories/')
      return response.data
    },
  })

  const vatRates = useQuery<VatRate[]>({
    queryKey: ['vatRates'],
    queryFn: async () => {
      const response = await api.get<VatRate[]>('/payments/vat-rates/')
      return response.data
    },
  })

  if (!ticketCategories || ticketCategories.isLoading || !vatRates || vatRates.isLoading) {
    return null
  }

  return (
    <Formik
      initialValues={initialValues as InitialValues}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t('ValidationMessages.required', 'Is required')),
        pricePer: Yup.number().when('isBundledOnly', {
          is: false,
          then: (schema) => schema.required(t('ValidationMessages.required', 'Is required')),
        }),
        vatRate: Yup.string().when('isBundledOnly', {
          is: false,
          then: (schema) => schema.required(t('ValidationMessages.required', 'Is required')),
        }),
        minQuantity: Yup.number().required(t('ValidationMessages.required', 'Is required')),
      })}
      onSubmit={onSubmit}
      enableReinitialize>
      {({ values, dirty }) => (
        <Form className="flex flex-col">
          <InputField
            name="isActive"
            type="switch"
            label={
              values.isActive ? t('Fields.active', 'Active') : t('Fields.inactive', 'Inactive')
            }
            className="pb-2 self-end text-lg"
            invertedLabel
          />
          <InputField name="name" type="text" label={t('Fields.name', 'Name')} className="pb-2" />
          <InputField
            name="minQuantity"
            type="number"
            label={t('Fields.minQuantity', 'Min Quantity')}
            className="pb-2"
          />
          <InputField
            name="pricePer"
            type="number"
            label={t('Fields.pricePer', 'Price per')}
            className="pb-2"
          />
          <InputField
            name="vatRate"
            type="select"
            label={t('Fields.vatRate', 'Vat Rate')}
            className="pb-2"
            selectValues={(vatRates.data || []).map((vr) => {
              return {
                id: vr.id,
                name: vr.rate.toString(),
              }
            })}
          />
          <InputField
            name="isFavorite"
            type="checkbox"
            label={t('Fields.isFavorite', 'Is favorite')}
            description={t(
              'FieldDescs.isFavorite',
              "If checked, this ticket type will show up in the favorites category, as well as in it's own category.",
            )}
            className="py-2"
          />
          <InputField
            name="description"
            type="text"
            label={t('Fields.description', 'Description')}
            className="pb-2"
          />
          <InputField
            name="position"
            type="number"
            label={t('Fields.position', 'Position')}
            className="pb-2"
          />
          <Button type="submit" className="pt-4" loading={loading} disabled={!dirty}>
            {submitLabel} {t('General.ticketType', 'Ticket Type')}
          </Button>
        </Form>
      )}
    </Formik>
  )
}
