import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { PanelHeader, RightPanel, TransportForm } from '~components'
import { useAddTransport, useRenderError, useToastConfirm, useToastError } from '~hooks'
import { CreateTransportErrors } from '~types'

export const TransportAddPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    mutateAsync: addTransport,
    isPending: loadingAddTransport,
    error: addTransportError,
    reset: resetAddTransport,
  } = useAddTransport()

  const addError = useRenderError({
    error: addTransportError,
    defaultMessage: t('CreateTransportScreen.createError', "Couldn't create transport."),
  })
  const queryClient = useQueryClient()
  const showError = useToastError()
  const showConfirm = useToastConfirm()

  React.useEffect(() => {
    resetAddTransport()
  }, [])

  React.useEffect(() => {
    if (addError) {
      showError(addError)
    }
  }, [addError])

  return (
    <RightPanel>
      <PanelHeader
        returnsTo="/transports"
        title={t('CreateTransportScreen.title', 'Create a new Transport')}
        subtitle={t('CreateTransportScreen.subtitle', 'Include a new transport into your fleet')}
      />
      <TransportForm
        onSubmit={async (values, actions) => {
          try {
            await addTransport({ transport: values })
            queryClient.invalidateQueries({ queryKey: ['transports'] })
            actions.resetForm()
            showConfirm(t('CreateTransportScreen.createdMessage', 'Transport created successfully'))
            navigate('/transports')
          } catch (e) {
            actions.setErrors(e as CreateTransportErrors)
          }
        }}
        loading={loadingAddTransport}
      />
    </RightPanel>
  )
}
