import { toast } from 'react-toastify'
import React from 'react'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useRenderError = ({
  error,
  defaultMessage,
}: {
  error: any
  defaultMessage?: string
}) => {
  // TODO: get rid of this
  /* 
    Usage:
    const formError = useRenderError({
      error: localError,
      defaultMessage: "Couldn't do it.",
    })
  */
  return typeof error === 'string' ? error : error ? defaultMessage : null
}

export const useMergeFormValues = ({
  initialValues,
  defaultVal,
}: {
  initialValues: any
  defaultVal: object
}) => {
  type Default = typeof defaultVal
  const updatedObject = initialValues
    ? Object.fromEntries(
        Object.entries(initialValues).map(([key, value]) => {
          const newValue =
            value === null
              ? Object.prototype.hasOwnProperty.call(defaultVal, key)
                ? defaultVal[key as keyof Default]
                : value
              : value
          return [key, newValue]
        }),
      )
    : defaultVal
  return { ...defaultVal, ...updatedObject }
}

// TODO: why in god's name would i need a hook for this?
export const useToastConfirm = () => (msg?: string | null) => toast(msg, { type: 'success' })
export const useToastError = () => (error?: string | null) => toast(error, { type: 'error' })

export const usePagination = (pageSize: number) => {
  const [offset, setOffset] = React.useState(0)
  const [count, setCount] = React.useState(0)
  const [page, setPage] = React.useState(1)
  const pages = Math.ceil(count / pageSize)

  const goNext = () => {
    if (page + 1 <= pages) {
      setPage(page + 1)
    }
  }
  const goBack = () => {
    if (page - 1 > 0) {
      setPage(page - 1)
    }
  }
  const goPage = (page: number) => {
    setPage(page)
  }

  React.useEffect(() => {
    setOffset(page * pageSize - pageSize)
  }, [page, pageSize])

  return { offset, count, setCount, goNext, goBack, goPage, activePage: page, setPage, pages }
}

/* eslint-enable @typescript-eslint/no-explicit-any */
