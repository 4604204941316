import React from 'react'
import Modal from 'react-modal'
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from '~components'
import { DeleteDependencies, ModelName } from '~types'

type DeleteConfirmationModalProps = {
  isOpen: boolean
  onRequestClose: () => void
  onDelete: () => void
  modelName: ModelName
  dependencies: DeleteDependencies
  resourceName: string
  loading?: boolean
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '90%',
    maxWidth: '90%',
  },
}

export const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
  isOpen,
  onRequestClose,
  onDelete,
  modelName,
  dependencies,
  resourceName,
  loading,
}) => {
  const { t } = useTranslation()
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} ariaHideApp={false} style={customStyles}>
      <div className="flex flex-col">
        <h1 className="text-2xl font-bold pb-8 text-center">
          {t('DeleteConfirmModal.deleteResourceConfirmText', 'Are you sure?')}
        </h1>
        <p className="text-center pb-8">
          <Trans
            i18nKey="DeleteConfirmModal.deleteResourceConfirmMessage"
            values={{
              resource: resourceName,
            }}
            components={{
              strong: <strong />,
            }}
          />
        </p>
        <p className="pb-2">
          {t('DeleteConfirmModal.resourcesListMessage', 'These elements will be deleted')}:
        </p>
        <div className="hs-accordion-group bg-warning">
          {Object.entries(dependencies).map(([key, values]) => (
            <div
              className="hs-accordion active bg-transparent p-3 list-inside text-gray-800 border -mt-px first:rounded-t-lg last:rounded-b-lg"
              id="hs-basic-heading-one"
              key={'depType' + key}>
              <button
                className="hs-accordion-toggle hs-accordion-active:text-teal-600 inline-flex items-center gap-x-3 w-full font-semibold text-start text-gray-800 hover:text-gray-500 rounded-lg disabled:opacity-50 disabled:pointer-events-none"
                aria-controls="hs-basic-collapse-one">
                <PlusIcon className="hs-accordion-active:hidden block w-4 h-4" />
                <MinusIcon className="hs-accordion-active:block hidden w-4 h-4" />
                {t(`General.${key}`, key)}
              </button>
              <div
                id="hs-basic-collapse-one"
                className="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
                aria-labelledby="hs-basic-heading-one">
                <ul className="list-disc list-inside pt-2 pl-6">
                  {values.map((value) => (
                    <li key={'depVal' + key + value}>{value}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-around w-full mt-4">
          <Button onClick={onRequestClose}>{t('General.goBack', 'Go back')}</Button>
          <Button onClick={onDelete} style="danger" loading={loading}>
            {t('General.delete', 'Delete')} {t(`General.${modelName}`, modelName)}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
