import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Trans, useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { FormDisplay, PanelHeader, RightPanel, UserForm } from '~components'
import { useRenderError } from '~hooks'
import { EditUserProps, User } from '~types'
import useApi from '~api/api'

export const UserEditPage = () => {
  const { t } = useTranslation()
  const api = useApi()
  const queryClient = useQueryClient()
  const { userId } = useParams<{ userId: string }>()
  if (!userId) {
    throw Error('You must provide a userId using the url.')
  }
  const navigate = useNavigate()

  const { mutateAsync: editUser, isPending: updatingUser } = useMutation({
    mutationFn: async ({ user }: { user: EditUserProps }) => {
      const response = await api.put<User>(`/users/${user.id}/`, user)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] })
      toast.success(t('EditUserScreen.updatedMesage', 'User updated successfully.'))
      navigate('/users')
    },
    onError: () => {
      toast.error(t('EditUserScreen.updateError', "Couldn't update User."))
    },
  })

  const {
    data: initialValues,
    isLoading: loadingUser,
    error: fetchUserError,
  } = useQuery<User>({
    queryKey: ['user', userId],
    queryFn: async () => {
      const response = await api.get<User>(`/users/${userId}/`)
      return response.data
    },
  })

  const fetchError = useRenderError({
    error: fetchUserError,
    defaultMessage: t('EditUserScreen.fetchError', "Couldn't get User."),
  })

  return (
    <RightPanel>
      <PanelHeader
        returnsTo="/users"
        title={t('EditUserScreen.title', 'Edit User.')}
        subtitle={
          <p>
            <Trans
              i18nKey={`EditUserScreen.subtitle`}
              values={{
                resource: initialValues?.email,
              }}
              components={{
                strong: <strong />,
              }}
            />
          </p>
        }
      />
      <FormDisplay isLoading={loadingUser} error={fetchError}>
        {initialValues && (
          <UserForm
            submitType="edit"
            initialValues={initialValues}
            onSubmit={async (values) => {
              await editUser({ user: values as EditUserProps })
            }}
            loading={updatingUser}
          />
        )}
      </FormDisplay>
    </RightPanel>
  )
}
