import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { useAuth } from '~hooks'
import { Button, HeaderLayout, InputField } from '~components'

export const RequestPasswordResetPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()

  const valSchema = yup.object().shape({
    email: yup
      .string()
      .email()
      .required(t('ValidationMessages.emailRequired', 'An email is required.')),
  })

  const handleSubmit = async ({ email }: { email: string }) => {
    await auth.requestPasswordReset({ email })
  }

  return (
    <HeaderLayout>
      <div className="w-full h-full py-4 px-8">
        <div className="flex flex-col items-center">
          <p className="pt-4">
            {t('RequestPasswordResetScreen.text', "We'll send and email to reset your password.")}
          </p>
          <Formik
            validationSchema={valSchema}
            initialValues={{ email: '' }}
            onSubmit={async (values, helpers) => {
              helpers.setSubmitting(true)
              await handleSubmit({ ...values })
              helpers.setSubmitting(false)
            }}>
            {({ dirty, isValid, isSubmitting }) => (
              <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-sm flex flex-col">
                <Form className="space-y-6 pt-8">
                  <div>
                    <InputField name="email" label={t('Fields.email', 'Email')} type="email" />
                  </div>
                  <div className="flex justify-center">
                    <Button disabled={!isValid || !dirty || isSubmitting} type="submit">
                      {t(
                        'RequestPasswordResetScreen.requestPasswordReset',
                        'Request password reset',
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </HeaderLayout>
  )
}
