import React from 'react'
import { Link } from 'react-router-dom'
import { ArrowLeftIcon } from '@heroicons/react/24/solid'

export const PanelHeader: React.FC<{
  returnsTo: string
  title?: string | React.ReactNode
  subtitle?: string | React.ReactNode
}> = ({ returnsTo, title, subtitle }) => {
  return (
    <div>
      <div className="grid grid-cols-[auto_1fr] gap-4">
        <Link to={returnsTo} className="inline-flex items-center text-sm font-medium text-gray-900">
          <ArrowLeftIcon className="w-5 h-5 mr-2" />
        </Link>
        <p className="text-2xl">{title}</p>
      </div>
      {subtitle && typeof subtitle !== 'string' && <div className="py-4">{subtitle}</div>}
      {subtitle && typeof subtitle === 'string' && <p className="py-4">{subtitle}</p>}
    </div>
  )
}
