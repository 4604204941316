import { Link, Outlet } from 'react-router-dom'
import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { Button, HeaderLayout, TableDisplay, TicketCategoriesTable } from '~components'
import { TicketCategory } from '~types'
import useApi from '~api/api'

export const TicketCategoriesListPage = () => {
  const { t } = useTranslation()
  const api = useApi()

  const {
    data: ticketCategories,
    isLoading: loadingListTicketCategories,
    error: listTicketCategoriesError,
  } = useQuery<TicketCategory[]>({
    queryKey: ['categories'],
    queryFn: async () => {
      const response = await api.get<TicketCategory[]>('/tickets/categories/')
      return response.data
    },
  })

  return (
    <HeaderLayout>
      <div className="relative h-full">
        <div className="flex flex-col p-8 h-full">
          <div>
            <Link
              to="/ticket-categories/add"
              className="inline-flex items-center text-sm font-medium text-gray-900 py-4">
              <Button>{t('ListCategoriesScreen.newCategory', 'New Ticket Category.')}</Button>
            </Link>
          </div>
          <TableDisplay
            items={ticketCategories || []}
            isLoading={loadingListTicketCategories}
            error={listTicketCategoriesError}>
            <TicketCategoriesTable ticketCategories={ticketCategories || []} />
          </TableDisplay>
        </div>
        <div className="fixed right-0 top-0 h-full">
          <Outlet />
        </div>
      </div>
    </HeaderLayout>
  )
}
