import { Form, Formik } from 'formik'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { HeaderLayout, InputField } from '~components'
import { useAuth } from '~hooks'

export const LoginPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()

  const valSchema = yup.object().shape({
    email: yup
      .string()
      .email()
      .required(t('ValidationMessages.emailRequired', 'An email is required.')),
    password: yup
      .string()
      .required(t('ValidationMessages.passwordRequired', 'A password is required')),
  })

  type HandleLoginFunc = (data: { email: string; password: string }) => void
  const handleLogin: HandleLoginFunc = ({ email, password }) => {
    auth.login(email, password)
  }

  return (
    <HeaderLayout>
      <div className="w-full h-full py-4 px-8">
        <div>
          <div className="flex min-h-full flex-1 flex-col justify-center items-center px-6 pt-6 pb-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                {t('SignInScreen.pleaseSignIn', 'Please sign in')}
              </h2>
            </div>
            <Formik
              validationSchema={valSchema}
              initialValues={{ email: '', password: '' }}
              onSubmit={(values, helpers) => {
                handleLogin(values)
                helpers.setSubmitting(false)
              }}>
              {({ dirty, isValid, isSubmitting }) => (
                <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-sm">
                  <Form className="space-y-6">
                    <div>
                      <InputField
                        name="email"
                        label={t('SignInScreen.emailText', 'Email')}
                        type="email"
                      />
                    </div>
                    <div>
                      <InputField
                        name="password"
                        label={t('SignInScreen.passwordText', 'Password')}
                        type="password"
                      />
                    </div>
                    <div>
                      <button
                        disabled={!isValid || !dirty || isSubmitting}
                        type="submit"
                        className="btn-primary block w-full">
                        {t('SignInScreen.login', 'Login')}
                      </button>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
            <div className="pt-4">
              <Link
                to="/request-password-reset"
                className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900">
                {t('SignInScreen.forgotPasword', 'Forgot password?')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </HeaderLayout>
  )
}
