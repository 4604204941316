import React from 'react'
import { Outlet } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

import { HeaderLayout, TableDisplay, VouchersTable } from '~components'
import { useListVouchers } from '~hooks'

export const VouchersListPage = () => {
  const {
    data: vouchers,
    isLoading: loadingListVouchers,
    error: listVouchersError,
  } = useListVouchers()
  const queryClient = useQueryClient()

  React.useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['vouchers'] })
  }, [])

  return (
    <HeaderLayout>
      <div className="relative h-full">
        <div className="flex flex-col p-8 h-full">
          <TableDisplay
            items={vouchers || []}
            isLoading={loadingListVouchers}
            error={listVouchersError}>
            <VouchersTable vouchers={vouchers || []} />
          </TableDisplay>
        </div>
        <div className="fixed right-0 top-0 h-full">
          <Outlet />
        </div>
      </div>
    </HeaderLayout>
  )
}
