import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Trans, useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { FormDisplay, PanelHeader, RightPanel, TicketTypeForm } from '~components'
import { useRenderError } from '~hooks'
import { EditTicketTypeProps, TicketType } from '~types'
import useApi from '~api/api'

export const TicketTypeEditPage = () => {
  const { t } = useTranslation()
  const api = useApi()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  // const navigate = useNavigate()
  const { ticketTypeId: ticketId, categoryId } = useParams<{
    ticketTypeId: string
    categoryId: string
  }>()

  const { mutateAsync: editTicket, isPending: updatingTicketType } = useMutation({
    mutationFn: async ({ ticketType }: { ticketType: EditTicketTypeProps }) => {
      const response = await api.put<TicketType>(`/tickets/${ticketType.uid}/`, ticketType)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['categories', categoryId, 'tickets'] })
      navigate(`/ticket-categories/${categoryId}/tickets`)
    },
    onError: () => toast.error(t('EditTicketScreen.updateError', "Couldn't update Ticket.")),
  })

  const {
    data: ticket,
    isLoading: loadingTicket,
    error: fetchTicketError,
  } = useQuery<TicketType>({
    queryKey: ['categories', categoryId, 'tickets', ticketId],
    queryFn: async () => {
      const response = await api.get<TicketType>(`/tickets/${ticketId}/`)
      return response.data
    },
    enabled: !!categoryId && !!ticketId,
  })

  const fetchError = useRenderError({
    error: fetchTicketError,
    defaultMessage: t('EditTicketScreen.fetchError', "Couldn't get Ticket Type."),
  })

  if (fetchTicketError) {
    toast.error(t('EditTicketScreen.fetchError', "Couldn't get Ticket"))
  }

  if (!ticketId || !ticket || loadingTicket) {
    return null
  }

  return (
    <RightPanel>
      <PanelHeader
        returnsTo={`/ticket-categories/${categoryId}/tickets/`}
        title={t('EditTicketScreen.title', 'Edit Ticket')}
        subtitle={
          <p>
            <Trans
              i18nKey={`EditTicketScreen.subtitle`}
              values={{
                resource: ticket?.name,
              }}
              components={{
                strong: <strong />,
              }}
            />
          </p>
        }
      />
      <FormDisplay isLoading={loadingTicket} error={fetchError}>
        {ticket && (
          <TicketTypeForm
            submitLabel={t('General.update', 'Update')}
            initialValues={{
              ...ticket,
              vatRate: `${ticket.vatRate.id}`,
              category: `${categoryId}`,
            }}
            onSubmit={async (values) => {
              await editTicket({ ticketType: values as EditTicketTypeProps })
            }}
            loading={updatingTicketType}
          />
        )}
      </FormDisplay>

      {/* Bundled Tickets */}
      <Outlet />

      <div className="h-64"></div>
    </RightPanel>
  )
}
