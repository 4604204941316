import React from 'react'
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import moment from 'moment'

import { TicketCategory } from '~types'
import { Button, Table } from '~components'

type TicketCategoriesTableProps = {
  ticketCategories: TicketCategory[]
}

export const TicketCategoriesTable: React.FC<TicketCategoriesTableProps> = ({
  ticketCategories,
}) => {
  const { t } = useTranslation()
  const [sorting, setSorting] = React.useState<SortingState>([])
  const columnHelper = createColumnHelper<TicketCategory>()

  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: 'name',
      cell: (info) => (
        <Link to={`/ticket-categories/${info.row.original.id}/edit`}>
          <i>{info.getValue()}</i>
        </Link>
      ),
      header: () => t('Fields.name', 'Name'),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('description', {
      header: () => t('Fields.description', 'Description'),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('position', {
      header: () => t('Fields.position', 'Position'),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('isActive', {
      header: () => t('Fields.isActive', 'Is active'),
      footer: (info) => info.column.id,
      cell: (info) =>
        info.getValue() ? (
          <CheckCircleIcon className="w-4 h-4 text-green-700" />
        ) : (
          <XCircleIcon className="w-4 h-4 text-red-700" />
        ),
    }),
    columnHelper.accessor('createdAt', {
      header: () => t('Fields.createdAt', 'Created'),
      cell: (info) => moment(info.getValue()).format('DD-MM-YYYY HH:mm'),
      footer: (info) => info.column.id,
    }),
    columnHelper.display({
      header: 'Actions',
      cell: (info) => (
        <Link to={`/ticket-categories/${info.row.original.id}/tickets`}>
          <Button>{t('ListCategoriesScreen.viewTickets', 'View Tickets')}</Button>
        </Link>
      ),
    }),
  ]
  const table = useReactTable<TicketCategory>({
    data: ticketCategories,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  })

  return <Table table={table} key="cat" />
}
