import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { AxisOptions, Chart } from 'react-charts'
import { useQuery } from '@tanstack/react-query'
import numeral from 'numeral'
import { Outlet, useNavigate } from 'react-router-dom'

import { Button, HeaderLayout, StatCard, Top5Table } from '~components'
// import authService from '~api/auth.service'
import 'react-datepicker/dist/react-datepicker.css'
import useApi from '~api/api'

interface DashboardPageProps {}

type TopTicketTypeCount = {
  ticketsSoldTicketTypeName: string
  ticketsSoldTicketTypeCategoryName: string
  count: number
}

type TopTicketTypeAmount = {
  ticketsSoldTicketTypeName: string
  ticketsSoldTicketTypeCategoryName: string
  total: number
}

type Stats = {
  amountSold: number
  numTransactions: number
  numTickets: number
  topTicketTypeCount: TopTicketTypeCount[]
  topTicketTypeAmount: TopTicketTypeAmount[]
  hourlyTotals: {
    hour: number
    amountSold: number
    ticketsSold: number
  }[]
}

type ChartSerieData = { hour: number; total: number }
type ChartSerie = {
  label: string
  data: ChartSerieData[]
}

export const DashboardPage: React.FC<DashboardPageProps> = () => {
  const { t } = useTranslation()
  const api = useApi()
  const navigate = useNavigate()
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'))
  const [refreshInterval, setRefreshInterval] = useState(300000)
  const [salesChartData, setSalesChartData] = useState<ChartSerie[]>([])
  const [ticketsChartData, setTicketsChartData] = useState<ChartSerie[]>([])

  const stats = useQuery<Stats, Error>({
    queryKey: ['stats', selectedDate],
    queryFn: async () => {
      const response = await api.get<Stats>(`/stats/?query_date=${selectedDate}`)
      return response.data
    },
    enabled: !!selectedDate,
    staleTime: 120000,
    refetchInterval: refreshInterval,
    refetchIntervalInBackground: true,
  })

  const formatter = (value: number) => numeral(value).format('0,0.00')
  const hourFormatter = (value: number) => `${numeral(value).format('00')}:00`

  const organizeDataForChart = useCallback(
    (label: string, valueName: 'amountSold' | 'ticketsSold') => [
      {
        label: label,
        data: stats.data
          ? stats.data.hourlyTotals.map((hourlyTotal) => ({
              hour: hourlyTotal.hour,
              total: hourlyTotal[valueName],
            }))
          : [],
      },
    ],
    [stats.data?.hourlyTotals],
  )

  useEffect(() => {
    setSalesChartData(organizeDataForChart('Total Sales', 'amountSold'))
    setTicketsChartData(organizeDataForChart('Total Tickets', 'ticketsSold'))
  }, [stats.data?.hourlyTotals])

  const primaryAxis = useMemo<AxisOptions<(typeof salesChartData)[number]['data'][number]>>(
    () => ({
      getValue: (datum) => {
        return datum.hour
      },
      scaleType: 'linear',
      formatters: {
        scale: hourFormatter,
        tooltip: hourFormatter,
        cursor: hourFormatter,
      },
    }),
    [stats.data?.hourlyTotals],
  )

  const secondaryAxes = useMemo<AxisOptions<(typeof salesChartData)[number]['data'][number]>[]>(
    () => [
      {
        getValue: (datum) => {
          return datum.total
        },
        elementType: 'bar',
        scaleType: 'linear',
        minDomainLength: 1,
        min: 0,
        hardMin: 0,
        shouldNice: true,
        formatters: {
          tooltip: formatter,
          cursor: formatter,
        },
      },
    ],
    [stats.data?.hourlyTotals],
  )

  if (!stats.data || stats.isLoading) {
    return <></>
  }

  return (
    <HeaderLayout>
      <div className="w-full px-8 py-6 grid grid-rows-[auto_auto_auto_1fr_1fr] gap-6">
        <div className="w-full flex justify-between">
          <div className="flex items-center">
            <DatePicker
              className="inline-block rounded-md border p-1.5 text-center text-sm text-teal-800 shadow-sm"
              todayButton={t('DashboardScreen.today', 'Today')}
              closeOnScroll={true}
              maxDate={moment().toDate()}
              selected={moment(selectedDate).toDate()}
              onChange={(date) => setSelectedDate(moment(date).format('YYYY-MM-DD'))}
            />
            <button
              onClick={() => setSelectedDate(moment().format('YYYY-MM-DD'))}
              className="ml-4 inline-block rounded-md px-4 py-1 text-center text-base font-medium text-white bg-teal-600 hover:bg-teal-800">
              Today
            </button>
          </div>
          <div className="flex items-center">
            <div className="mt-1 text-sm text-gray-400">
              Auto-update every{' '}
              <select
                value={refreshInterval}
                className="mx-1 rounded-md border-gray-300 py-1 pl-2 pr-6 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                onChange={(e) => setRefreshInterval(parseInt(e.target.value, 10))}>
                <option value="180000">3</option>
                <option value="300000">5</option>
                <option value="600000">10</option>
                <option value="1800000">30</option>
              </select>
              minutes. last updated at {moment(stats.dataUpdatedAt).format('DD-MM-YYYY HH:mm')}
            </div>
          </div>
          <div className="flex items-center">
            <Button onClick={() => navigate('/export')}>
              {t('DashboardScreen.exportReport', 'Export report')}
            </Button>
          </div>
        </div>
        <hr />
        <div className="grid grid-flow-col gap-6">
          <StatCard
            title={t('DashboardScreen.amountSold', 'Sales')}
            formattedValue={numeral(stats.data.amountSold).format('0,0.00')}
          />
          <StatCard
            title={t('DashboardScreen.ticketsSold', 'Tickets Sold')}
            formattedValue={numeral(stats.data.numTickets).format('0')}
          />
        </div>

        <div className="w-full grid grid-cols-2 gap-6">
          <div className="border p-2">
            <p>{t('DashboardScreen.salesByDayHour', 'Sales per hour')}</p>
            {salesChartData && (
              <div className="w-full h-128">
                <Chart
                  options={{
                    data: salesChartData,
                    primaryAxis,
                    secondaryAxes,
                    defaultColors: ['#0d9488'],
                  }}
                />
              </div>
            )}
          </div>
          <div className="border p-2">
            <p>{t('DashboardScreen.ticketsByDayHour', 'Tickets per hour')}</p>
            {ticketsChartData && (
              <div className="w-full h-128">
                <Chart
                  options={{
                    data: ticketsChartData,
                    primaryAxis,
                    secondaryAxes,
                    defaultColors: ['#0d9488'],
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <div className="w-full grid grid-cols-2 gap-6">
          <div className="border p-2">
            <Top5Table
              data={stats.data.topTicketTypeAmount}
              showTotal
              tableHeader={t(
                'DashboardScreen.top5EarningsByTicketType',
                'Top 5 Ticket Types by Sales',
              )}
            />
          </div>
          <div className="border p-2">
            <Top5Table
              data={stats.data.topTicketTypeCount}
              showCount
              tableHeader={t('DashboardScreen.top5SoldTicketTypes', 'Top 5 Ticket Types Sold')}
            />
          </div>
        </div>
      </div>
      <div className="fixed right-0 top-0 h-full">
        <Outlet />
      </div>
    </HeaderLayout>
  )
}
