import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'

import { APP_VERSION, CS_URL } from '~constants'
import { useAuth } from '~hooks'

type GenericDict = { [key: string]: any } // eslint-disable-line @typescript-eslint/no-explicit-any

const useApi = () => {
  const auth = useAuth()

  const logoutUser = () => {
    auth.logout()
  }

  const refreshToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken')
    const response = await request.post<{ access: string }>('/users/token/refresh/', {
      refresh: refreshToken,
    })
    const { access } = response.data
    auth.refresh(access)
  }
  const newAbortSignal = (timeoutMs: number | undefined) => {
    const abortController = new AbortController()
    setTimeout(() => abortController.abort(), timeoutMs || 0)

    return abortController.signal
  }

  const request = axios.create({
    baseURL: `${CS_URL}/api/manage`,
    timeout: 10000,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      AppVersion: APP_VERSION,
    },
  })

  request.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem('token')
      if (token) {
        config['headers']['Authorization'] = `Bearer ${token}`
      }
      if (config['headers']['Content-Type'] === 'application/json') {
        config.data = decamelizeKeys(config.data, {
          split: /(?=[A-Z])|(?=(?<![1-9_])[1-9])/,
        })
      }
      const locale = window.localStorage.getItem('locale')
      if (locale) {
        config.headers['Accept-Language'] = locale
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  request.interceptors.response.use(
    async (response) => {
      return camelizeKeys(response) as AxiosResponse<any> // eslint-disable-line @typescript-eslint/no-explicit-any
    },
    async (error) => {
      const originalConfig = error.config
      if (
        !['/users/token/refresh/', '/users/token/'].includes(originalConfig.url) &&
        error.response &&
        [401, 403].includes(error.response.status) &&
        !originalConfig._retry
      ) {
        originalConfig._retry = true
        try {
          await refreshToken()
          return request(originalConfig)
        } catch (err) {
          logoutUser()
        }
      }
      return Promise.reject(camelizeKeys(error) as AxiosResponse<any>) // eslint-disable-line @typescript-eslint/no-explicit-any
    },
  )

  const get = async <T>(
    resource: string,
    params?: GenericDict,
    options?: GenericDict,
  ): Promise<AxiosResponse<T>> => {
    return await request<T>({
      method: 'get',
      signal: newAbortSignal(10000), //Aborts request after 10 seconds
      url: resource,
      config: request.defaults,
      params,
      ...options,
    } as AxiosRequestConfig)
      .then((response) => {
        // __DEV__ && console.log('GET', resource, 'response', response, 'request', response.config);
        return response
      })
      .catch((error) => {
        // __DEV__ && console.log('GET', resource, 'error', error, 'url', serviceUrl);
        return handleError(error)
      })
  }

  const post = async <T>(
    resource: string,
    payload: GenericDict,
    options?: GenericDict,
  ): Promise<AxiosResponse<T>> => {
    return await request({
      method: 'post',
      url: resource,
      data: payload,
      config: request.defaults,
      ...options,
    } as AxiosRequestConfig)
      .then((response) => {
        // __DEV__ && console.log('GET', resource, 'response', response, 'request', response.config);
        return response
      })
      .catch((error) => {
        // __DEV__ && console.log('GET', resource, 'error', error, 'url', serviceUrl);
        return handleError(error)
      })
  }

  const put = async <T>(
    resource: string,
    payload: GenericDict,
    options?: GenericDict,
  ): Promise<AxiosResponse<T>> => {
    return await request({
      method: 'put',
      url: resource,
      data: payload,
      config: request.defaults,
      ...options,
    } as AxiosRequestConfig)
      .then((response) => {
        // __DEV__ && console.log('GET', resource, 'response', response, 'request', response.config);
        return response
      })
      .catch((error) => {
        // __DEV__ && console.log('GET', resource, 'error', error, 'url', serviceUrl);
        return handleError(error)
      })
  }

  const _delete = async <T>(resource: string, options?: GenericDict): Promise<AxiosResponse<T>> => {
    // an underscore is used to avoid conflict with the delete function in the axios library
    return await request({
      method: 'delete',
      url: resource,
      config: request.defaults,
      ...options,
    } as AxiosRequestConfig)
      .then((response) => {
        // __DEV__ && console.log('GET', resource, 'response', response, 'request', response.config);
        return response
      })
      .catch((error) => {
        // __DEV__ && console.log('GET', resource, 'error', error, 'url', serviceUrl);
        return handleError(error)
      })
  }

  const handleError = (error: AxiosError) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request)
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message)
    }
    console.log(error.config)
    throw error
  }

  return { get, post, put, _delete, handleError }
}

export default useApi
