import React from 'react'
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { Voucher } from '~types'
import { Table } from '~components'

type VouchersTableProps = {
  vouchers: Voucher[]
}

export const VouchersTable: React.FC<VouchersTableProps> = ({ vouchers }) => {
  const columnHelper = createColumnHelper<Voucher>()

  const columns = [
    columnHelper.accessor((row) => row.id, {
      id: 'code',
      header: () => 'Code',
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('status', {
      header: () => 'Status',
      cell: (info) => info.renderValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('expirationDate', {
      header: () => 'Expiration Date',
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('externalId', {
      header: 'External ID',
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('integration', {
      header: 'Intregration',
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('createdAt', {
      header: 'Created At',
      footer: (info) => info.column.id,
    }),
  ]
  const table = useReactTable<Voucher>({
    data: vouchers,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <Table table={table} key="vou">
      {/* <THead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={'vouHead' + headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TH key={'vouCol' + header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(header.column.columnDef.header, header.getContext())}
              </TH>
            ))}
          </tr>
        ))}
      </THead>
      <TBody>
        {table.getRowModel().rows.map((row) => (
          <tr key={'vouRow' + row.id}>
            {row.getVisibleCells().map((cell) => (
              <TD key={'vouInfo' + cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TD>
            ))}
          </tr>
        ))}
      </TBody> */}
    </Table>
  )
}
