import React from 'react'
import clsx from 'clsx'
import { flexRender, Table as TTable } from '@tanstack/react-table'
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/solid'

type TableElProps = {
  children: React.ReactNode
} & React.HTMLAttributes<HTMLElement>

const THead: React.FC<TableElProps> = ({ children, ...props }) => {
  return (
    <thead className="bg-gray-50" {...props}>
      {children}
    </thead>
  )
}

const TBody: React.FC<TableElProps> = ({ children, ...props }) => {
  return (
    <tbody className="divide-y divide-gray-200" {...props}>
      {children}
    </tbody>
  )
}

const TH: React.FC<TableElProps & { isAction?: boolean }> = ({ children, isAction, ...props }) => {
  return (
    <th
      scope="col"
      className={clsx(
        isAction ? 'text-end' : 'text-start',
        'px-6 py-3 text-xs font-medium text-gray-500 uppercase',
      )}
      {...props}>
      {children}
    </th>
  )
}

export const TD: React.FC<TableElProps & { isAction?: boolean }> = ({
  children,
  isAction,
  ...props
}) => {
  return (
    <td
      className={clsx(
        isAction ? 'text-end text-sm' : 'text-sm text-gray-800',
        'px-6 py-4 whitespace-nowrap font-medium',
      )}
      {...props}>
      {children}
    </td>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
type TableProps = {
  key: string
  table: TTable<any>
  withSearch?: boolean
  onSearch?: (term: string) => void
  withPagination?: true
  onNextPage?: () => void
  onPreviousPage?: () => void
  onPage?: (page: number) => void
  pages?: number
  activePage?: number
} & React.HTMLAttributes<HTMLElement>
/* eslint-enable @typescript-eslint/no-explicit-any */

export const Table: React.FC<TableProps> = ({
  key,
  table,
  onSearch,
  withSearch,
  withPagination,
  onNextPage,
  onPreviousPage,
  onPage,
  pages,
  activePage,
  ...props
}) => {
  return (
    <div className="flex flex-col pb-14">
      <div className="-m-1.5 overflow-x-auto">
        <div className="p-1.5 min-w-full inline-block align-middle">
          <div className="border rounded-lg divide-y divide-gray-200">
            {withSearch && (
              <div className="py-3 px-4">
                <div className="relative max-w-xs">
                  <label className="sr-only">Search</label>
                  <input
                    type="text"
                    name="hs-table-with-pagination-search"
                    id="hs-table-with-pagination-search"
                    className="py-2 px-3 ps-9 block w-full border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-teal-500 focus:ring-teal-500 disabled:opacity-50 disabled:pointer-events-none"
                    placeholder="Search for items"
                    onChange={(e) => (onSearch ? onSearch(e.target.value) : null)}
                  />
                  <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none ps-3">
                    <svg
                      className="h-4 w-4 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round">
                      <circle cx="11" cy="11" r="8" />
                      <path d="m21 21-4.3-4.3" />
                    </svg>
                  </div>
                </div>
              </div>
            )}

            <div className="overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200" {...props}>
                <THead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={`${key}Head` + headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <TH key={`${key}Col` + header.id}>
                          {header.isPlaceholder ? null : (
                            <div
                              {...{
                                className: header.column.getCanSort()
                                  ? 'cursor-pointer select-none flex'
                                  : '',
                                onClick: header.column.getToggleSortingHandler(),
                              }}>
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {{
                                asc: <ArrowUpIcon className="w-4 h-4 ml-2" />,
                                desc: <ArrowDownIcon className="w-4 h-4 ml-2" />,
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          )}
                        </TH>
                      ))}
                    </tr>
                  ))}
                </THead>
                <TBody>
                  {table.getRowModel().rows.map((row) => (
                    <tr key={`${key}Row` + row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <TD key={`${key}Info` + cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TD>
                      ))}
                    </tr>
                  ))}
                </TBody>
              </table>
            </div>

            {withPagination && (
              <div className="py-1 px-4">
                <nav className="flex items-center space-x-1">
                  <button
                    onClick={onPreviousPage}
                    type="button"
                    className="p-2.5 inline-flex items-center gap-x-2 text-sm rounded-full text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none">
                    <span aria-hidden="true">«</span>
                    <span className="sr-only">Previous</span>
                  </button>
                  {pages &&
                    onPage &&
                    Array.from({ length: pages }, (_, i) => i + 1).map((page) => (
                      <button
                        key={page}
                        onClick={() => {
                          onPage(page)
                        }}
                        type="button"
                        className={clsx(
                          page === activePage && 'bg-gray-100',
                          'min-w-[40px] flex justify-center items-center text-gray-800 hover:bg-gray-100 py-2.5 text-sm rounded-full disabled:opacity-50 disabled:pointer-events-none',
                        )}
                        aria-current="page">
                        {page}
                      </button>
                    ))}
                  <button
                    onClick={onNextPage}
                    type="button"
                    className="p-2.5 inline-flex items-center gap-x-2 text-sm rounded-full text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none">
                    <span className="sr-only">Next</span>
                    <span aria-hidden="true">»</span>
                  </button>
                </nav>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
