import React from 'react'
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { SoldTicket } from '~types'
import { Table } from '~components'

type SalesTableProps = {
  soldTickets: SoldTicket[]
  onNextPage?: () => void
  onPreviousPage?: () => void
  onPage?: (page: number) => void
  pages?: number
  activePage?: number
}

export const SalesTable: React.FC<SalesTableProps> = ({
  soldTickets,
  onNextPage,
  onPreviousPage,
  onPage,
  pages,
  activePage,
}) => {
  const { t } = useTranslation()
  const columnHelper = createColumnHelper<SoldTicket>()

  const columns = [
    columnHelper.accessor((row) => row.uid, {
      id: 'id',
      header: () => 'ID',
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('ticketTypeName', {
      header: () => t('Fields.ticketTypeName', 'Ticket type'),
      cell: (info) => info.renderValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('price', {
      header: () => t('Fields.price', 'Price'),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('vatRate', {
      header: t('Fields.vatRate', 'VAT rate'),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('paymentMethod', {
      header: t('Fields.paymentMethod', 'Payment method'),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('driverName', {
      header: t('Fields.driverName', "Driver's name"),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('vehicle', {
      header: t('Fields.vehicle', 'Vehicle'),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('createdAt', {
      header: t('Fields.createdAt', 'Created at'),
      footer: (info) => info.column.id,
    }),
  ]
  const table = useReactTable<SoldTicket>({
    data: soldTickets,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <Table
      table={table}
      key="sales"
      withPagination
      pages={pages}
      activePage={activePage}
      onPage={onPage}
      onNextPage={onNextPage}
      onPreviousPage={onPreviousPage}></Table>
  )
}
