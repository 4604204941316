import { useQuery } from '@tanstack/react-query'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import useApi from '~api/api'
import { PanelHeader, RightPanel } from '~components'
import { BaseTicketType, TicketType } from '~types'

export const TicketTypeViewPage = () => {
  const { t } = useTranslation()
  const { ticketId, categoryId } = useParams<{ ticketId: string; categoryId: string }>()
  const api = useApi()

  const {
    data: ticket,
    isLoading: loadingTicket,
    error: fetchTicketError,
  } = useQuery<BaseTicketType>({
    queryKey: ['category', categoryId, 'tickets', ticketId],
    queryFn: async () => {
      const response = await api.get<TicketType>(`/tickets/${ticketId}/`)
      return response.data
    },
    enabled: !!categoryId && !!ticketId,
  })

  if (!ticket || loadingTicket) {
    return null
  }

  if (fetchTicketError) {
    return <h1>Ticket loading error</h1>
  }

  return (
    <RightPanel>
      <PanelHeader
        returnsTo={`/ticket-categories/${categoryId}/tickets/`}
        title={t('EditTicketScreen.title', 'Edit Ticket')}
        subtitle={
          <p>
            <Trans
              i18nKey={`EditTicketScreen.subtitle`}
              values={{
                resource: ticket.name,
              }}
              components={{
                strong: <strong />,
              }}
            />
          </p>
        }
      />
      <div className="flex flex-row w-full">
        <div className="flex flex-col w-full">
          <div className="w-20 font-medium">Name</div>
          <div>{ticket.name}</div>
        </div>
      </div>
    </RightPanel>
  )
}
