import React from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { PanelHeader, RightPanel, UserForm } from '~components'
import { CreateUserProps, User } from '~types'
import useApi from '~api/api'

export const UserAddPage = () => {
  const api = useApi()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { mutateAsync: addUser, isPending: loadingAddUser } = useMutation({
    mutationFn: async ({ user }: { user: CreateUserProps }) => {
      const response = await api.post<User>(`/users/`, user)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] })
      toast.success(t('CreateUserScreen.createdMessage', 'User created successfully.'))
      navigate('/users')
    },
    onError: () => {
      toast.error(t('CreateUserScreen.createError', "Couldn't create User."))
    },
  })

  return (
    <RightPanel>
      <PanelHeader
        returnsTo="/users"
        title={t('CreateUserScreen.title', 'Create a new User')}
        subtitle={t('CreateUserScreen.subtitle', 'Enroll a new User in the system.')}
      />
      <UserForm
        submitType="create"
        initialValues={
          {
            firstName: '',
            lastName: '',
            email: '',
            isActive: true,
          } as CreateUserProps
        }
        onSubmit={async (values, actions) => {
          await addUser(
            { user: values as CreateUserProps },
            { onSuccess: () => actions.resetForm() },
          )
        }}
        loading={loadingAddUser}
      />
    </RightPanel>
  )
}
