interface Props {
  title: string
  formattedValue: string
}

export const StatCard: React.FC<Props> = ({ title, formattedValue }): React.ReactElement => {
  return (
    <div className="overflow-hidden rounded-lg bg-white shadow">
      <div className="p-5">
        <div className="flex items-center">
          <div className="ml-5 w-0 flex-1">
            <dl>
              <dt className="truncate text-center text-sm font-medium text-gray-500">{title}</dt>
              <dd>
                <div className="text-center text-3xl font-medium text-gray-900">
                  {formattedValue}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}
