import useApi from './api'

import {
  CreateTicketCategoryProps,
  CreateTicketTypeBundleProps,
  CreateTicketTypeProps,
  EditTicketCategoryProps,
  EditTicketTypeProps,
  TicketCategory,
  TicketCategoryID,
  TicketType,
  TicketTypeBundle,
  TicketTypeID,
} from '~types'

const addTicketCategory = async ({
  ticketCategory,
}: {
  ticketCategory: CreateTicketCategoryProps
}) => {
  const api = useApi()
  const response = await api.post<TicketCategory>(`/tickets/categories/`, ticketCategory)
  return response.data
}

const getTicketCategory = async ({ categoryId }: { categoryId: TicketCategoryID }) => {
  const api = useApi()
  const response = await api.get<TicketCategory>(`/tickets/categories/${categoryId}/`)
  return response.data
}

const editTicketCategory = async ({
  ticketCategory,
}: {
  ticketCategory: EditTicketCategoryProps
}) => {
  const api = useApi()
  const response = await api.put<TicketType>(
    `/tickets/categories/${ticketCategory.id}/`,
    ticketCategory,
  )
  return response.data
}

const listTicketTypes = async (categoryId: string | undefined) => {
  const api = useApi()
  const response = await api.get<TicketType[]>(`/tickets/categories/${categoryId}/tickets/`)
  return response.data
}

const listBundledOnlyTicketTypes = async () => {
  const api = useApi()
  const response = await api.get<TicketType[]>('/tickets/?is_bundled_only=True')
  return response.data
}

const addTicketType = async ({ ticketType }: { ticketType: CreateTicketTypeProps }) => {
  const api = useApi()
  const response = await api.post<TicketType>(`/tickets/`, ticketType)
  return response.data
}

const getTicketType = async ({ typeId }: { typeId: TicketTypeID }) => {
  const api = useApi()
  const response = await api.get<TicketType>(`/tickets/${typeId}/`)
  return response.data
}

const editTicketType = async ({ ticketType }: { ticketType: EditTicketTypeProps }) => {
  const api = useApi()
  const response = await api.put<TicketType>(`/tickets/${ticketType.uid}/`, ticketType)
  return response.data
}

const listSoldTickets = async (offset: number = 0, pageSize: number = 10) => {
  const api = useApi()
  const response = await api.get(`/tickets/sales/?limit=${pageSize}&offset=${offset}`)
  return response.data
}

const listBundledTickets = async ({ typeId }: { typeId: TicketTypeID }) => {
  const api = useApi()
  const response = await api.get<TicketTypeBundle[]>(`/tickets/${typeId}/bundles/`)
  return response.data
}

const addBundledTicket = async ({
  ticketTypeBundle,
}: {
  ticketTypeBundle: CreateTicketTypeBundleProps
}) => {
  const api = useApi()
  const response = await api.post<TicketTypeBundle>('/tickets/bundles/', { ...ticketTypeBundle })
  return response.data
}

const removeBundledTicket = async ({ bundleId }: { bundleId: string }): Promise<void> => {
  const api = useApi()
  await api._delete(`/tickets/bundles/${bundleId}/`)
}

const ticketsService = {
  addTicketCategory,
  getTicketCategory,
  editTicketCategory,
  listTicketTypes,
  addTicketType,
  getTicketType,
  editTicketType,
  listSoldTickets,
  listBundledTickets,
  addBundledTicket,
  removeBundledTicket,
  listBundledOnlyTicketTypes,
}

export default ticketsService
