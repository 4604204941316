import { useTranslation } from 'react-i18next'

export const LanguageSelect = () => {
  const { i18n } = useTranslation()

  return (
    <select
      name="locale"
      value={i18n.language}
      onChange={(e) => {
        window.localStorage.setItem('locale', e.target.value)
        i18n.changeLanguage(e.target.value)
      }}
      className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 bg-white-800 text-gray-800 ring-1 ring-inset ring-teal-700 focus:ring-2 focus:ring-teal-900 sm:text-sm sm:leading-6">
      <option value="en">English</option>
      <option value="fr">Français</option>
      <option value="es">Español</option>
      <option value="it">Italiano</option>
      <option value="de">Deutsch</option>
    </select>
  )
}
