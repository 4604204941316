import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { APP_VERSION } from '~constants'

export const Footer = () => {
  const { t } = useTranslation()
  const CURRENT_YEAR = moment().format('YYYY')
  return (
    <div className="flex flex-col align-middle justify-center bg-gray-100 h-10 fixed bottom-0 left-0 right-0 text-center">
      &copy; {CURRENT_YEAR}, Theta - {t('General.rightsReserved')} - version {APP_VERSION}
    </div>
  )
}
