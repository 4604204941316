/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react'
import { useTranslation } from 'react-i18next'

import { FormSkeleton } from '~components'

type FormDisplayProps = {
  children: React.ReactNode
  isLoading?: boolean
  error?: unknown
  loadingEl?: React.ReactNode
  errorEl?: React.ReactNode
}

export const FormDisplay: React.FC<FormDisplayProps> = ({
  children,
  isLoading,
  error,
  loadingEl,
  errorEl,
}) => {
  const { t } = useTranslation()
  const renderError = (error: any) => {
    if (errorEl) {
      return errorEl
    }
    if (Object.prototype.hasOwnProperty.call(error, 'message')) {
      return <p>Error: {JSON.stringify(error.message)}</p>
    }
    if (error) {
      return <p>{error}</p>
    }
    return <p>{t('General.unknownError', 'There was an error.')}</p>
  }

  return (
    <>
      {isLoading ? (
        loadingEl ? (
          loadingEl
        ) : (
          <FormSkeleton />
        )
      ) : error ? (
        renderError(error)
      ) : (
        <>{children}</>
      )}
    </>
  )
}

/* eslint-enable @typescript-eslint/no-explicit-any */
