import { Link, Outlet } from 'react-router-dom'
import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { Button, HeaderLayout, TableDisplay, UsersTable } from '~components'
import { User } from '~types'
import useApi from '~api/api'

export const UsersListPage = () => {
  const { t } = useTranslation()
  const api = useApi()
  const {
    data: users,
    isLoading: loadingListUsers,
    error: listUsersError,
  } = useQuery<User[]>({
    queryKey: ['users'],
    queryFn: async () => {
      const response = await api.get<User[]>('/users/')
      return response.data
    },
  })

  return (
    <HeaderLayout>
      <div className="relative h-full">
        <div className="flex flex-col p-8 h-full">
          <div>
            <Link
              to="/users/add"
              className="inline-flex items-center text-sm font-medium text-gray-900 py-4">
              <Button>{t('ListUsersScreen.newUser', 'New User')}</Button>
            </Link>
          </div>
          <TableDisplay items={users || []} isLoading={loadingListUsers} error={listUsersError}>
            <UsersTable users={users || []} />
          </TableDisplay>
        </div>
        <div className="fixed right-0 top-0 h-full">
          <Outlet />
        </div>
      </div>
    </HeaderLayout>
  )
}
