import React from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import * as yup from 'yup'
import YupPassword from 'yup-password'
import { useTranslation } from 'react-i18next'

import { Button, InputField } from '~components'

YupPassword(yup)
type InitialValues = {
  password: string
}
type PasswordInputProps = {
  loading?: boolean
  onSubmit: (values: InitialValues, actions: FormikHelpers<InitialValues>) => void
}

export const PasswordInput: React.FC<PasswordInputProps> = ({ onSubmit, loading = false }) => {
  const { t } = useTranslation()

  const valSchema = yup.object().shape({
    password: yup
      .string()
      .required(t('ValidationMessages.passwordRequired', 'A password is required.'))
      .min(9)
      .minLowercase(1),
    passwordConfirmation: yup
      .string()
      .required(
        t('ValidationMessages.passwordConfirmationRequired', 'Password confirmation is required.'),
      )
      .oneOf([yup.ref('password'), ''], 'Passwords must match'),
  })

  return (
    <Formik validationSchema={valSchema} initialValues={{ password: '' }} onSubmit={onSubmit}>
      {({ dirty, isValid }) => (
        <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-sm">
          <Form className="space-y-6">
            <div className="max-w-sm">
              <div className="flex mb-2">
                <div className="flex-1 relative">
                  <InputField
                    type="password"
                    id="password-input"
                    className="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                    label={t('Fields.newPassword', 'New Password')}
                    name="password"
                    noErrors
                  />
                  <InputField
                    type="password"
                    id="passwordConfirmation"
                    className="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                    label={t('Fields.passwordConfirmation', 'Confirm password')}
                    name="passwordConfirmation"
                  />
                  <div
                    id="hs-strong-password"
                    data-hs-strong-password='{
                        "target": "#password-input",
                        "hints": "#hs-strong-password-hints",
                        "minLength": 9,
                        "checksExclude": ["uppercase", "numbers", "special-characters"],
                        "stripClasses": "hs-strong-password:opacity-100 hs-strong-password-accepted:bg-teal-500 h-2 flex-auto rounded-full bg-blue-500 opacity-50 mx-1"
                      }'
                    className="flex mt-2 -mx-1 px-4"></div>
                </div>
              </div>

              <div id="hs-strong-password-hints" className="mb-3 px-4">
                <div>
                  <span className="text-sm text-gray-800">
                    {t('General.passwordLevel', 'Level')}:
                  </span>
                  <span
                    data-hs-strong-password-hints-weakness-text='["Empty", "Weak", "Strong", "Very Strong", "Super Strong"]'
                    className="text-sm font-semibold text-gray-800"></span>
                </div>

                <h4 className="my-2 text-sm font-semibold text-gray-800">
                  {t('General.passwordPolicyTitle', 'Your password must contain')}:
                </h4>

                <ul className="space-y-1 text-sm text-gray-500">
                  <li
                    data-hs-strong-password-hints-rule-text="min-length"
                    className="hs-strong-password-active:text-teal-500 flex items-center gap-x-2">
                    <span className="hidden" data-check>
                      <svg
                        className="flex-shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <polyline points="20 6 9 17 4 12" />
                      </svg>
                    </span>
                    <span data-uncheck>
                      <svg
                        className="flex-shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <path d="M18 6 6 18" />
                        <path d="m6 6 12 12" />
                      </svg>
                    </span>
                    {t('General.passwordPolicyMinCharacters', 'Minimum number of characters is 9.')}
                  </li>
                  <li
                    data-hs-strong-password-hints-rule-text="lowercase"
                    className="hs-strong-password-active:text-teal-500 flex items-center gap-x-2">
                    <span className="hidden" data-check>
                      <svg
                        className="flex-shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <polyline points="20 6 9 17 4 12" />
                      </svg>
                    </span>
                    <span data-uncheck>
                      <svg
                        className="flex-shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <path d="M18 6 6 18" />
                        <path d="m6 6 12 12" />
                      </svg>
                    </span>
                    {t('General.passwordPolicyLowercase', 'Should contain lowercase.')}
                  </li>
                  <li
                    data-hs-strong-password-hints-rule-text="uppercase"
                    className="hs-strong-password-active:text-teal-500 flex items-center gap-x-2">
                    <span className="hidden" data-check>
                      <svg
                        className="flex-shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <polyline points="20 6 9 17 4 12" />
                      </svg>
                    </span>
                    <span data-uncheck>
                      <svg
                        className="flex-shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <path d="M18 6 6 18" />
                        <path d="m6 6 12 12" />
                      </svg>
                    </span>
                    Should contain uppercase.
                  </li>
                  <li
                    data-hs-strong-password-hints-rule-text="numbers"
                    className="hs-strong-password-active:text-teal-500 flex items-center gap-x-2">
                    <span className="hidden" data-check>
                      <svg
                        className="flex-shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <polyline points="20 6 9 17 4 12" />
                      </svg>
                    </span>
                    <span data-uncheck>
                      <svg
                        className="flex-shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <path d="M18 6 6 18" />
                        <path d="m6 6 12 12" />
                      </svg>
                    </span>
                    Should contain numbers.
                  </li>
                  <li
                    data-hs-strong-password-hints-rule-text="special-characters"
                    className="hs-strong-password-active:text-teal-500 flex items-center gap-x-2">
                    <span className="hidden" data-check>
                      <svg
                        className="flex-shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <polyline points="20 6 9 17 4 12" />
                      </svg>
                    </span>
                    <span data-uncheck>
                      <svg
                        className="flex-shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <path d="M18 6 6 18" />
                        <path d="m6 6 12 12" />
                      </svg>
                    </span>
                    Should contain special characters.
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex justify-center">
              <Button disabled={!isValid || !dirty || loading} type="submit">
                {t('General.resetPassword', 'Reset Password')}
              </Button>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  )
}
