import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'preline/preline'

import { Main } from './routing'
import { AuthProvider } from './hooks'
import './languages'

const App = () => {
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <AuthProvider>
        <ToastContainer />
        <Main />
      </AuthProvider>
    </QueryClientProvider>
  )
}

export default App
