import { Link, Outlet, useParams } from 'react-router-dom'
import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleLeft } from '@fortawesome/pro-regular-svg-icons'

import { Button, HeaderLayout, TableDisplay, TicketTypesTable } from '~components'
import { TicketCategory, TicketType } from '~types'
import useApi from '~api/api'

export const TicketTypesListPage = () => {
  const { t } = useTranslation()
  const api = useApi()
  const { categoryId } = useParams<{ categoryId: string }>()

  const category = useQuery<TicketCategory>({
    queryKey: ['categories', categoryId],
    queryFn: async () => {
      const response = await api.get<TicketCategory>(`/tickets/categories/${categoryId}/`)
      return response.data
    },
    enabled: categoryId !== undefined,
  })

  const {
    data: ticketTypes,
    isLoading: loadingListTicketTypes,
    error: listTicketTypesError,
  } = useQuery<TicketType[]>({
    queryKey: ['categories', categoryId, 'tickets'],
    queryFn: async () => {
      const response = await api.get<TicketType[]>(`/tickets/categories/${categoryId}/tickets/`)
      return response.data
    },
    enabled: categoryId !== undefined,
  })

  if (!category || category.isLoading) {
    return null
  }

  return (
    <HeaderLayout>
      <div className="relative h-full">
        <div className="flex flex-col p-8 h-full">
          <div className="flex flex-row justify-between items-center">
            <div>
              <Link to="/ticket-categories">
                <FontAwesomeIcon icon={faChevronCircleLeft} size="2xl" className="text-teal-500" />
              </Link>
            </div>
            <div className="text-2xl text-gray-900 font-bold">
              Category: {category.data?.name || 'Loading...'}
            </div>
            <div>
              <Link
                to={`/ticket-categories/${categoryId}/tickets/add`}
                className="inline-flex items-center text-sm font-medium text-gray-900 py-4">
                <Button>{t('ListTicketsScreen.newTicket', 'New Ticket')}</Button>
              </Link>
            </div>
          </div>
          <TableDisplay
            items={ticketTypes || []}
            isLoading={loadingListTicketTypes}
            error={listTicketTypesError}>
            <TicketTypesTable ticketTypes={ticketTypes || []} />
          </TableDisplay>
        </div>
        <div className="fixed right-0 top-0 h-full">
          <Outlet />
        </div>
      </div>
    </HeaderLayout>
  )
}
