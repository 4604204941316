import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, InputField } from '~components'
import { useListTransportStatuses, useListTransportTypes, useMergeFormValues } from '~hooks'
import { CreateTransportProps, EditTransportProps } from '~types'

type InitialValues = CreateTransportProps | EditTransportProps

type TransportFormProps = {
  initialValues?: InitialValues
  onSubmit: (values: InitialValues, actions: FormikHelpers<InitialValues>) => void
  loading?: boolean
}

const DEFAULT = {
  id: '',
  identifier: '',
  lastOdometerReading: '',
  status: '',
  transportType: '',
  manufacturer: '',
  isActive: false,
}

export const TransportForm: React.FC<TransportFormProps> = ({
  initialValues,
  onSubmit,
  loading,
}: TransportFormProps) => {
  const { t } = useTranslation()
  const { data: transportStatuses } = useListTransportStatuses()
  const { data: transportTypes } = useListTransportTypes()
  const mergedInitialValues = useMergeFormValues({
    initialValues,
    defaultVal: DEFAULT,
  })

  return (
    <Formik
      initialValues={mergedInitialValues as InitialValues}
      validationSchema={Yup.object().shape({
        identifier: Yup.string().required(t('ValidationMessages.required', 'Is required')),
        status: Yup.string().required(t('ValidationMessages.required', 'Is required')),
        type: Yup.string().required(t('ValidationMessages.required', 'Is required')),
      })}
      onSubmit={onSubmit}
      enableReinitialize>
      {({ values, dirty }) => (
        <Form className="flex flex-col">
          <InputField
            name="isActive"
            type="switch"
            label={
              values.isActive ? t('Fields.active', 'Active') : t('Fields.inactive', 'Inactive')
            }
            className="pb-2 self-end text-lg"
            invertedLabel
          />
          <InputField
            name="identifier"
            type="text"
            label={t('Fields.identifier', 'Identifier')}
            className="pb-2"
          />
          <InputField
            name="status"
            type="select"
            label={t('Fields.status', 'Status')}
            selectValues={transportStatuses?.map((status) => ({
              id: status,
              name: t(`Dynamic.Transport.${status}`),
            }))}
            className="pb-2"
          />
          <InputField
            name="type"
            type="select"
            label={t('Fields.transportType', 'Transport type')}
            className="pb-2"
            selectValues={transportTypes?.map((type) => ({
              id: type,
              name: t(`Dynamic.Transport.${type}`),
            }))}
          />
          <InputField
            name="manufacturer"
            type="text"
            label={t('Fields.manufacturer', 'Manufacturer')}
            className="pb-2"
          />
          <Button type="submit" className="pt-4" loading={loading} disabled={!dirty}>
            {initialValues ? t('General.update', 'Update') : t('General.create', 'Create')}{' '}
            {t('General.transport', 'Transport')}
          </Button>
        </Form>
      )}
    </Formik>
  )
}
