import useApi from './api'

import {
  CreateTransportProps,
  EditTransportProps,
  Transport,
  TransportID,
  TransportStatus,
  TransportType,
} from '~types'

const listTransports = async () => {
  const api = useApi()
  const response = await api.get<Transport[]>('/transports/')
  return response.data
}

const addTransport = async ({ transport }: { transport: CreateTransportProps }) => {
  const api = useApi()
  const response = await api.post<Transport>(`/transports/`, transport)
  return response.data
}

const getTransport = async ({ transportId }: { transportId: TransportID }) => {
  const api = useApi()
  const response = await api.get<Transport>(`/transports/${transportId}/`)
  return response.data
}

const editTransport = async ({ transport }: { transport: EditTransportProps }) => {
  const api = useApi()
  const response = await api.put<Transport>(`/transports/${transport.id}/`, transport)
  return response.data
}

const deleteTransport = async ({ transportId }: { transportId: TransportID }) => {
  const api = useApi()
  const response = await api._delete(`/transports/${transportId}/`)
  return response.data
}

const listTransportTypes = async () => {
  const api = useApi()
  const response = await api.get<TransportType[]>('/transports/types/')
  return response.data
}

const listTransportStatuses = async () => {
  const api = useApi()
  const response = await api.get<TransportStatus[]>('/transports/statuses/')
  return response.data
}

const transportsService = {
  listTransports,
  addTransport,
  getTransport,
  editTransport,
  deleteTransport,
  listTransportTypes,
  listTransportStatuses,
}

export default transportsService
