import React from 'react'
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'

import { Transport } from '~types'
import { Table } from '~components'

type TransportsTableProps = {
  transports: Transport[]
}

export const TransportsTable: React.FC<TransportsTableProps> = ({ transports }) => {
  const { t } = useTranslation()
  const [sorting, setSorting] = React.useState<SortingState>([])
  const columnHelper = createColumnHelper<Transport>()

  const columns = [
    columnHelper.accessor((row) => row.identifier, {
      id: 'identifier',
      cell: (info) => (
        <Link to={`/transports/${info.row.original.id}/edit`}>
          <i>{info.getValue()}</i>
        </Link>
      ),
      header: () => t('Fields.identifier', 'Identifier'),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('type', {
      header: () => t('Fields.transportType', 'Type'),
      footer: (info) => info.column.id,
      cell: (info) => t(`Dynamic.Transport.${info.getValue()}`),
    }),
    columnHelper.accessor('status', {
      header: t('Fields.status', 'Status'),
      footer: (info) => info.column.id,
      cell: (info) => t(`Dynamic.Transport.${info.getValue()}`),
    }),
    columnHelper.accessor('manufacturer', {
      header: () => t('Fields.manufacturer', 'Manufacturer'),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('isActive', {
      header: () => t('Fields.isActive', 'Is active'),
      footer: (info) => info.column.id,
      cell: (info) =>
        info.getValue() ? (
          <CheckCircleIcon className="w-4 h-4 text-green-700" />
        ) : (
          <XCircleIcon className="w-4 h-4 text-red-700" />
        ),
    }),
    columnHelper.accessor('createdAt', {
      header: () => t('Fields.createdAt', 'Created At'),
      footer: (info) => info.column.id,
    }),
  ]
  const table = useReactTable<Transport>({
    data: transports,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return <Table table={table} key="tran" />
}
